<template>
  <div v-cloak :class="`${!isDesktop ? 'mobile' : '' }`">
    <nuxt />

    <transition name="toTop">
      <Error v-if="error" :error="error" />
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    Error: () => import('~/components/Error.vue')
  },
  computed: {
    ...mapState(['isDesktop']),
    error() {
      return this.$store.state.error
    }
  }
}
</script>

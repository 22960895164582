export const strict = false

export const state = () => ({
  user: null,
  error: '',
  lang: 'en',
  isDesktop: null,
  currentFav: null,
  gloading: '',
})

export const mutations = {
  setGloading(state, payload) {
    state.gloading = payload
  },
  setCurrentFav(state, payload) {
    state.currentFav = payload
  },
  setLang(state, payload) {
    state.lang = payload
  },
  setDevice(state, payload) {
    state.isDesktop = payload.isDesktop
  },
  setUser(state, payload) {
    state.user = payload
  },
  setError(state, payload) {
    state.error = payload
  },
  clearError(state) {
    state.error = ''
  }
}

export const actions = {
  nuxtServerInit({ commit }, { req, app, error }) {
    try {
      if (req.isAuthenticated()) {
        commit('setUser', req.user)
        // commit('setPopular', req.user.popular)
      }

      commit('setDevice', app.$device)

      // const langs = ['ru', 'en']
      // const locales = req.headers["accept-language"]
      //   .slice(0, req.headers["accept-language"].indexOf(';'))
      //   .split(',')
      // let lang = 'en'
      // for(let i = 0; i < locales.length; i++) {
      //   if (langs.includes(locales[i])) {
      //     lang = locales[i]
      //     break
      //   }
      // }
      commit('setLang', 'en')
    } catch (e) {
      error('Error on [nuxtServerInit] action', e)
    }
  }
}

<template>
  <div v-cloak :class="`${!isDesktop ? 'mobile' : '' }`">
    <ul class="cp-menu">
      <li>
        <nuxt-link to="/cp/blocked">blocked</nuxt-link>
      </li>
      <li>
        <nuxt-link to="/cp/token">token</nuxt-link>
      </li>
      <li>
        <nuxt-link to="/cp/desc">desc</nuxt-link>
      </li>
    </ul>


    <nuxt />

    <transition name="toTop">
      <Error v-if="error" :error="error" />
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    Error: () => import('~/components/Error.vue')
  },
  computed: {
    ...mapState(['isDesktop']),
    error() {
      return this.$store.state.error
    }
  }
}
</script>

<style lang="stylus" scoped>
.cp-menu
  li
    display: inline-block
    margin: 10px
    a
      color: #fff
</style>
